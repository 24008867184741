import React from "react"
// import { Link } from "gatsby"

// s4n
import { useIntl, Link } from "gatsby-plugin-intl"
// import MenuUtils from "./../../s4n/Menu/utils"



const ComponentStateless = (props) => {
    const intl = useIntl();

    const { to, prefix, children } = props;

    let urlProcessed = to;
    let childrenProcessed = children;

    // if (Number.isInteger(to)) {
    //     const node = MenuUtils.getItemByNodeId(to, intl.locale);

    //     if (null === node) {
    //         return null;
    //     }

    //     urlProcessed = node.link.url;

    //     if (typeof children === `undefined`) {
    //         childrenProcessed = node.title;
    //     }
    // }

    let propsToPass = {
        ...props
    }

    propsToPass.intl && delete propsToPass.intl;

    return <Link {...propsToPass} to={urlProcessed}>{prefix}{childrenProcessed}</Link>
}

export default ComponentStateless