// import { Link } from "gatsby"
import React from "react"
import { connect } from 'react-redux'

// s4n
import { useIntl, Link } from "gatsby-plugin-intl"
// import Link from './s4n/Link' // extends Link from gatsby-plugin-intl
import loadable from '@loadable/component'
import Preloader from './s4n/Preloader'
import classNames from 'classnames'
// import UseDrupalBlockContentBasic from './../hooks/drupal/blockContentBasic'
// import ImageS4n from "./s4n/image"
//import cssModule from "./../assets/sass/components/Header.module.scss"
// import useBuildTime from './../hooks/useBuildTime'
// ES6
//import Webform from 'hn-react-webform';
import GeneralUtils from './../utils/common'
// import Menu from "./s4n/Menu/menu"
// import MenuUtils from "./s4n/Menu/utils"

import Logo from './../images/custom/logo.png'
// import LogoWhite from './../images/custom/logo-white-48.png'
// import { ReactComponent as MapPoland } from './../images/custom/POL_location_map.svg'
// import PromotedFrontPage from './s4n/Promoted/FrontPage'



const Footer = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { hasFooterContactForm } = props;

    // const nodeContact = MenuUtils.getItemByNodeId(5, intl.locale);
    // const buildTime = useBuildTime();
    // const SvgMapPoland = loadable(() => import(`./svg/map-poland`), {fallback: <Preloader />})
    // const FormHeuresisNewsletter = loadable(() => import(`./../components/forms/webform/heuresis_newsletter`), {fallback: <Preloader />})

    const oClassNames = classNames({
        "section-padding": true,
        // "footer-section": hasFooterContactForm,
        // [`alert-${formStatus.class}`]: true,
    });

    const sEmailAddress = `biuro.proklima@gmail.com`;
    // const sPhoneNumber = ``;
    
    return (
        <React.Fragment>
            <section id="footer" className={oClassNames}>
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-2 col-md-6">
                            <div className="footer-widget footer-link">
                                <h4>Blog</h4>
                                <ul>
                                    <li><a href="#">About</a></li>
                                    <li><a href="#">Service</a></li>
                                    <li><a href="#">Pricing</a></li>
                                    <li><a href="#">Team</a></li>
                                    <li><a href="#">Testimonials</a></li>
                                    <li><a href="#">Blog</a></li>
                                </ul>
                                <Menu type="footer" className="menu-footer" disableChildren={true} _disableDropdown={true} disableLanguage={true} _nodeLinkUriRoot="entity:node/1058" />
                            </div>
                        </div> */}
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget footer-text">
                                <h4>{ t({ id: `soft4net_footer_contact` }) }</h4>
                                <p>Norbert Maroszek PROKLIMA</p>
                                <p>www.proklima.com.pl</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget footer-text">
                                <h4>{ t({ id: `soft4net_footer_phone` }) }</h4>
                                <p><a href="tel:+48787757521"><i className="fa fa-phone-square" aria-hidden="true"></i> (+48) 787 757 521</a></p>
                                {/* <p><a href="tel:+48730710475"><i className="fa fa-phone-square" aria-hidden="true"></i>  (+48) 730 710 475</a></p> */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget footer-text">
                                <h4>E-mail &amp; Facebook</h4>
                                <p><a data-encoded={GeneralUtils.rot13(sEmailAddress)} data-prefix={`<i class="fa fa-envelope" aria-hidden="true"></i> `} href="mailto:"></a></p>
                                <p><a href="https://www.facebook.com/proklimaklimatyzacja" target="_blank"><i className="fab fa-facebook-square"></i> fb/proklimaklimatyzacja</a></p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget footer-text">
                                <h4>{intl.locale === `en` ? `Address` : `Adres`}</h4>
                                <p>Ściborzyce 17</p>
                                <p>32-353 Trzyciąż</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="footer-copy">
                                <p>
                                    <Link to={`/`} title={`Proklima`} className={`logo`}>
                                        <img 
                                            style={{ width: `5rem` }} 
                                            // src={props.isHighContrastMode ? LogoWhite : Logo} 
                                            src={Logo} 
                                            alt={`Proklima`} className="logo" />
                                    </Link>
                                </p>
                                <p>
                                    <Link to={`/polityka-prywatnosci`} className="px-3">Polityka prywatności</Link>
                                </p>
                                <p>Copyright &copy; {new Date().getFullYear()} Wszelkie prawa zastrzeżone. Copyright Norbert Maroszek PROKLIMA</p>
                                <a 
                                    href="https://www.soft4net.pl/" 
                                    title={ t({ id: `soft4net_project_realization_anchor_title` }) } 
                                    target="_blank"
                                    rel="noopener"
                                >{ t({ id: `soft4net_project_realization` }) }</a>
                                {/* {`true` === process.env.SOFT4NET_DISPLAY_BUILD_TIME_IN_FOOTER && 
                                    <p className="mt-5">
                                        { t({ id: "soft4net_deploy_build_time" }) }: {GeneralUtils.dateFormat(buildTime, "LLL", intl.locale)}
                                    </p>
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Footer;