import React from "react"
// import PropTypes from "prop-types"
import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"

// s4n
// @see: https://github.com/gatsbyjs/gatsby/issues/1875
// import Link from "./Link"
// import { Location } from '@reach/router'
// import { globalHistory } from "@reach/router"
// import MenuUtils from "./../s4n/Menu/utils"
// import { useParseInlineImages } from '../../hooks/drupal/parseInlineImages'
import loadable from '@loadable/component'
import Preloader from '../s4n/Preloader'
// import FormBasic from "./../forms/basic"
// import FormBasicMaterialUiExample1 from "./../forms/basic.material-ui.example-1"
// import FormBasicMaterialUiExample2 from "./../forms/basic.material-ui.example-2"
// import FormBasicMaterialUiExample3 from "./../forms/basic.material-ui.example-3"
// import UseDrupalBlockContentBasic from './../../hooks/drupal/blockContentBasic'
// import FormContactS4n from "./../forms/webform/contact_s4n"
// import FormBasicMaterialUi1 from "./../forms/basic.material-ui.test"
// import FormFormikJsonSchema from "./../forms/formik-json-schema"
// import Harmonogram from './../../components/s4n/Harmonogram/harmonogram'
// import Shop from './Shop/index'

// import FormikJsonSchema from "./../forms/formik-json-schema"
// import ReactJsonSchema from "./../forms/react-jsonschema-form"
// import CircleInteractive from "./../s4n/CircleInteractive"

// import { Accordion, Card } from 'react-bootstrap' // TEST for src/assets/sass/fix/bootstrap/_transitions.scss

import GeneralUtils from './../../utils/common'
// import PromotedFrontPage from './../s4n/Promoted/FrontPage'
import GoogleMap from './../s4n/GoogleMap'
import FormContactS4n from './../forms/standalone/contact_s4n'



const sEmailAddress = `biuro.proklima@gmail.com`;

const ComponentFunctional = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { children, name, nodeId, productId, hasFooterContactForm } = props;
    
    return (
        <React.Fragment>
            {/* {(() => {
                return (
                    <React.Fragment>
                        This was rendered!
                    </React.Fragment>
                )
            })()} */}

            {children}

            <React.Fragment>
                <section className="section-padding kontakt contact-data">
                    <div className="container">
                        <div className="row">
                            <div className="col d-flex flex-column align-items-center justify-content-center">
                                <h2 className="banner-title">Kontakt</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-7 col-sm-12">
                                <FormContactS4n />
                            </div>
                            <div className="col-lg-5 pl-4 mt-4 mt-lg-0">
                                {/* <h3>Proklima</h3> */}
                                <h4>{intl.locale === `en` ? `Address` : `Adres`}</h4>
                                <p><i className="fas fa-home"></i> Ściborzyce 17, 32-353 Trzyciąż</p>
                                <h4 className="mt-3">{ t({ id: `soft4net_footer_phone` }) }</h4>
                                <p><a href="tel:+48787757521"><i className="fa fa-phone-square" aria-hidden="true"></i> (+48) 787 757 521</a></p>
                                {/* <p><a href="tel:+48730710475"><i className="fa fa-phone-square" aria-hidden="true"></i> (+48) 730 710 475</a></p> */}
                                <h4 className="mt-3">E-mail</h4>
                                <p><a data-encoded={GeneralUtils.rot13(sEmailAddress)} data-prefix={`<i class="fa fa-envelope" aria-hidden="true"></i> `} href="mailto:"></a></p>
                                <h4 className="mt-3">{intl.locale === `en` ? `Website` : `Strona internetowa`}</h4>
                                <p><i className="fas fa-globe"></i> www.proklima.com.pl</p>
                                <h4 className="mt-3">Facebook</h4>
                                <p><a href="https://www.facebook.com/proklimaklimatyzacja" target="_blank"><i className="fab fa-facebook-square"></i> fb/proklimaklimatyzacja</a></p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section id="map" className="section-padding ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-sm-6 col-md-3">
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <GoogleMap /> */}

                </React.Fragment>

        </React.Fragment>
    )
}

export default ComponentFunctional