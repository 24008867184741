// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import GeneralUtils from './../utils/common'

// s4n
// import GeneralUtils from './../utils/common'
import { useIntl } from "gatsby-plugin-intl"
import Link from './s4n/Link' // extends Link from gatsby-plugin-intl
// import Menu from "./s4n/Menu/menu"
// import MenuUtils from "./../components/s4n/Menu/utils"
// import LanguageSwitcher from './../components/languageSwitcher'
// import Search from './../components/forms/Search';
// import ImageS4n from "./s4n/image"
//import cssModule from "./../assets/sass/components/Header.module.scss"

// import Logo from './../images/custom/logo.svg'
import Logo from './../images/custom/logo.png'

// import { Navbar, Nav, NavDropdown } from 'react-bootstrap'

// import withDrupalOauthConsumer from '../components/drupal-oauth/withDrupalOauthConsumer';
// import SignIn from './s4n/AccountManagement/SignIn/SignIn';
// import LogoutLink from './s4n/AccountManagement/LogoutLink/LogoutLink';

// import Accessibility from './s4n/Accessibility'
// import PromotedFrontPage from './s4n/Promoted/FrontPage'
// import Accessibility from './s4n/Accessibility'



// Shop START
// import { connect } from 'react-redux'
// import { cartFlyoutOpen } from './s4n/Shop/ext/actions'
// const openFlyout = (dispatch) => dispatch(cartFlyoutOpen());
// Shop STOP



// ES6
//import Webform from 'hn-react-webform';

const Header = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { siteTitle } = props;



    // Shop START
    const {
      dispatch,
      // cart: { carts, itemCount },
      // cartFlyout: { open }
    } = props;
    // Shop STOP

    const sEmailAddress = `biuro.proklima@gmail.com`;

    return (
      <React.Fragment>
          <nav className="navbar navbar-expand-lg fixed-top trans-navigation header-white">
              <div className="container">
                  <Link to={`/`} title={siteTitle} className="navbar-brand">
                    <img src={Logo} alt={siteTitle} className="logo" _width="179" _height="50" />
                  </Link>

                  <button tabIndex="0" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNav" aria-controls="mainNav" aria-expanded="false" aria-label={t({ id: `soft4net_toggle_navigation` })}>
                      <span className="navbar-toggler-icon">
                          <i className="fa fa-bars"></i>
                      </span>
                  </button>

                  <div className="collapse navbar-collapse justify-content-end" id="mainNav">
                      <ul className="navbar-nav ">
                          {/* <li className="nav-item">
                              <a className="nav-link active smoth-scroll" href=".banner-area">Home <span className="sr-only">(current)</span></a>
                          </li> */}
                          <li className="nav-item">
                            <a className="nav-link smoth-scroll" href="tel:+48787757521"><i className="fa fa-phone-square" aria-hidden="true"></i> (+48) 787 757 521</a> 
                          </li>
                          <li className="nav-item">
                            <a className="nav-link smoth-scroll" data-encoded={GeneralUtils.rot13(sEmailAddress)} data-prefix={`<i class="fa fa-envelope" aria-hidden="true"></i> `} href="mailto:"></a>
                          </li>
                          <li className="nav-item">
                                <a className="nav-link smoth-scroll goto-scroll" href="#o-nas">O nas</a>
                          </li>
                          <li className="nav-item">
                                <a className="nav-link smoth-scroll goto-scroll" href="#kontakt">Kontakt</a>
                          </li>
                      </ul>
                  </div>
              </div>
          </nav>
      </React.Fragment>
    )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;