/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import Header from "./header"
import Footer from "./footer"

// import "./layout.css"
// import cssModule from "./../assets/sass/components/Layout.module.scss"
// import "./../../node_modules/bootstrap/scss/bootstrap.scss";
import "./../assets/sass/index.scss"
// import cssModuleHighContrast from "./../assets/sass/HighContrast.module.scss"
// import cssModuleHighContrast from "./../assets/sass/HighContrast.module.css"
// import "./../assets/sass/HighContrast.module.css"

// s4n
// import $ from "jquery"
import { useIntl, navigate } from "gatsby-plugin-intl"
import loadable from '@loadable/component'
// import Preloader from './s4n/Preloader'
import kebabCase from "lodash.kebabcase"
import classNames from 'classnames';
import S4nLayoutBlock from './s4n/layoutBlock'
// import * as loadScript from 'simple-load-script'
import loadScript from 'simple-load-script';
// @see: https://github.com/gatsbyjs/gatsby/issues/8755#issuecomment-456766798
import { withPrefix } from 'gatsby'
// import g_soft4NetFrontConfig from './../assets/js/s4n'
// import YupSetLocale from './../components/forms/yup-setlocale';

// import drupalOauth from './drupal-oauth/drupalOauth';
// import withDrupalOauthProvider from './drupal-oauth/withDrupalOauthProvider'

// import { connect } from 'react-redux'

// SHOP EXT START
// // import React, { Component } from 'react';
// // import { ApolloProvider } from 'react-apollo';
// // import { Route, Switch } from "react-router-dom";
// import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'connected-react-router'
// import configureStore, { history } from './../components/s4n/Shop/ext/utils/configureStore';
// // import { graphqlClient } from './utils/api';

// // import Header from './blocks/Header';
// // import CatalogMenu from './blocks/CatalogMenu'
// // import Footer from './blocks/Footer'
// // import Home from './pages/Home';
// import Cart from './../components/s4n/Shop/ext/pages/Cart';
// // import Catalog from './pages/Catalog';
// // import Product from './pages/Product';
// import { cartFetch, setCartToken } from './../components/s4n/Shop/ext/actions'
// import CartFlyout from './../components/s4n/Shop/ext/blocks/CartFlyout'
// // import Checkout from './pages/Checkout'

//     // @see: https://github.com/gatsbyjs/gatsby/issues/14480
//     /**
//      * I do localStorage (typeof window !== 'undefined') way instead of putting invocation into useEffect()
//      * because render is called before useEffect()!
//      */
//     // const store = configureStore();
//     // // if (typeof window !== 'undefined') {
//     //   store.dispatch(setCartToken(localStorage.getItem('cartToken') || Math.random().toString(36).substr(2)))
//     //   // store.dispatch(setCartToken('ajg6t2g3kz'));
//     //   store.dispatch(cartFetch());
//     // // }
// SHOP EXT STOP

// s4n
import useVersion from './../hooks/useVersion'
import useScreenSize from './../hooks/useScreenSize'

import Preloader from './s4n/Preloader'
// import CartFlyout from './../components/s4n/Shop/ext/blocks/CartFlyout'
// import ShopCheckoutIframe from './../components/s4n/Shop/s4n/Checkout/Iframe'
// import Breadcrumbs from './s4n/Menu/Breadcrumbs'



const Layout = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { children } = props;

    const [context, setContext] = useState({
      // loadedScripts: false,
      isHeroSlider: false,
      windowLoaded: false,
    });

    const currentBuildVersion = useVersion();

    // SHOP EXT START
    // @see: https://github.com/gatsbyjs/gatsby/issues/14480
    /**
     * I do localStorage (typeof window !== 'undefined') way instead of putting invocation into useEffect()
     * because render is called before useEffect()!
     */
    // const store = configureStore();
    // // if (typeof window !== 'undefined') {
    //   // store.dispatch(setCartToken(localStorage.getItem('cartToken') || Math.random().toString(36).substr(2)))
    //   store.dispatch(setCartToken('9jg6t2g3kz'));
    //   store.dispatch(cartFetch());
    // // }
    // SHOP EXT STOP
   
    /**
     * Load 3rd party scripts async in order (local static assets mainly thanks to withPrefix)!!!
     * 
     * @see: https://stackoverflow.com/questions/34589488/es6-immediately-invoked-arrow-function
     * @see: https://stackoverflow.com/questions/40958680/react-router-how-to-trigger-document-ready
     */
    /*
      #!/bin/bash

      # create symlinks from sticky directory for local npm packages that you want to inject into body
      cd static/
      ln -s ../node_modules/jquery/dist/jquery.min.js jquery.min.js
      ln -s ../node_modules/jquery/dist/jquery.slim.min.js jquery.slim.min.js
      // ln -s ../node_modules/bootstrap/dist/js/bootstrap.min.js bootstrap.min.js
      ln -s ../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js bootstrap.bundle.min.js
      ln -s ../node_modules/owl.carousel/dist/owl.carousel.min.js owl.carousel.min.js
      ln -s ../node_modules/waypoints/lib/jquery.waypoints.min.js jquery.waypoints.min.js
      ln -s ../node_modules/waypoints/lib/shortcuts/sticky.min.js sticky.min.js
      ln -s ../node_modules/magnific-popup/dist/jquery.magnific-popup.min.js jquery.magnific-popup.min.js
      ln -s ../node_modules/smooth-scroll/dist/smooth-scroll.polyfills.min.js smooth-scroll.polyfills.min.js
    */
    const loadScripts = async (nodeId, productId) => {
        // CDN assets
        // await loadScript('https://code.jquery.com/jquery-3.4.1.slim.min.js', { inBody: true });
        // await loadScript('https://code.jquery.com/jquery-3.4.1.min.js', { inBody: true });
        // await loadScript(withPrefix('/jquery.slim.min.js' + currentBuildVersion), { inBody: true }); // does not contain .animate() !!!
        await loadScript(withPrefix('/node_modules/jquery.min.js' + currentBuildVersion), { inBody: true });
        // await loadScript(withPrefix('/bootstrap.min.js' + currentBuildVersion), { inBody: true });
        // await loadScript(withPrefix('/bootstrap.bundle.min.js' + currentBuildVersion), { inBody: true });
        await loadScript(withPrefix('/node_modules/bootstrap.bundle.min.js' + currentBuildVersion), { inBody: true }); // bootstrap.bundle.min.js include Popper, but not jQuery
        await loadScript(withPrefix('/node_modules/jquery.waypoints.min.js' + currentBuildVersion), { inBody: true });
        // await loadScript(withPrefix('/sticky.min.js' + currentBuildVersion), { inBody: true });
// await loadScript(withPrefix('/node_modules/smooth-scroll.polyfills.min.js' + currentBuildVersion), { inBody: true });
        // await loadScript(withPrefix('/jquery.magnific-popup.min.js' + currentBuildVersion), { inBody: true });
        
        // Load only at page [/pl/o-nas/historia-i-fakty || /en/about-us/history-and-facts]
        // 1055 === nodeId && 
          // await loadScript(withPrefix('/scrollreveal.min.js' + currentBuildVersion), { inBody: true }); // await loadScript('https://unpkg.com/scrollreveal', { inBody: true });



        // 3rd party theme JS
        await loadScript(withPrefix('/promodise/plugins/counterup/wow.min.js' + currentBuildVersion), { inBody: true });
        await loadScript(withPrefix('/promodise/plugins/counterup/jquery.easing.1.3.js' + currentBuildVersion), { inBody: true });
        await loadScript(withPrefix('/promodise/plugins/counterup/jquery.counterup.min.js' + currentBuildVersion), { inBody: true });
        // await loadScript(withPrefix('/promodise/plugins/jquery/contact.js' + currentBuildVersion), { inBody: true });



        // @todo: use https://jscompress.com/
        await loadScript(withPrefix('/s4n.min.js' + currentBuildVersion), {
        // await loadScript(withPrefix('/s4n.js' + currentBuildVersion), {
          inBody: true,
          /**
           * @see: https://stackoverflow.com/questions/3206050/extract-src-attribute-from-script-tag-and-parse-according-to-particular-matches
           * @see: https://stackoverflow.com/questions/2190801/passing-parameters-to-javascript-files
           */
          // attrs: {
          //   'data-soft4net-source-base-url': process.env.SOFT4NET_SOURCE_BASE_URL,
          // }
        });

        // const jQuery = window.jQuery;
        // const $ = jQuery;

        // We have now available window.g_soft4NetFrontConfig from s4n.js !!!
        callScripts(nodeId, productId);
    }

    const callScripts = (nodeId, productId) => {
        const g_soft4NetFrontConfig = window.g_soft4NetFrontConfig;
        const $ = window.jQuery; // we want to have jQuery from our import not from => import $ from "jquery"

        $(document).ready(() => {
        // $(document).on('ready', function() { // NOT WORKING WHY???!!!
            g_soft4NetFrontConfig
                .rot13()
                // .stickyHeaderAction()
                .smoothClickScrolling()
                // .enableMagnificPopup(process.env.SOFT4NET_SOURCE_BASE_URL)
                // .enableScrollTopService()
                // .transformToBootstrapAccoridion()
                // .enableTimeline()
                // .enableServiceTawkTo()
                // .updateBootstrapMenu()
                // .scrollSpy()
                // .waypoints()
                // .owlCarousel()
                // .servicePopupScroll()
                // .fancybox()
            ;
        });

        // Run a function when the page is fully loaded including graphics
        // $(window).on('load', function() {
        // });

        // g_soft4NetFrontConfig
        //     // .webFont()
        //     // .navTransformicon()
        //     // .smoothClickScrolling()
        //     // .bootstrapMenu()
        //     // .stickyHeaderAction()
        //     // .otherActions()
        //     // .setScreenSize()
        //     // .owlCarousel('mainSlider', '.owl-carousel.hero')
        //     // .owlCarousel('centeredSlider', '.owl-carousel.centered')
        //     // .owlCarousel('standardSlider', '.owl-carousel.standard')
        //     // .owlCarousel('testimonialsSlider', '.owl-carousel.testimonials')
        // ;







  
        /* ----------------------------------------------------------- */
        /*  Fixed header
        /* ----------------------------------------------------------- */
        $(window).on('scroll', function () {
            if ($(window).scrollTop() > 70) {
                $('.site-navigation,.trans-navigation').addClass('header-white');
            } else {
                $('.site-navigation,.trans-navigation').removeClass('header-white');
            }
        });

        /* ==========================================================================
          SCROLL SPY
        ========================================================================== */
        $('body').scrollspy({
            target: '.navbar-collapse',
            offset: 195
        });

        /*START GOOGLE MAP*/
        // function initialize() {
        //   var mapOptions = {
        //     zoom: 15,
        //     scrollwheel: false,
        //     center: new google.maps.LatLng(40.7127837, -74.00594130000002)
        //   };
        //   var map = new google.maps.Map(document.getElementById('map'),
        //       mapOptions);
        //   var marker = new google.maps.Marker({
        //     position: map.getCenter(),
        //     icon: 'assets/img/map_pin.png',
        //     map: map
        //   });
        // }
        // google.maps.event.addDomListener(window, 'load', initialize);	
        /*END GOOGLE MAP*/	








      // $('li.dropdown').on('click', function() {
      //   let $el = $(this);
      //   if ($el.hasClass('show')) {
      //       let $a = $el.children('a.dropdown-toggle');

      //       if ($a.length && $a.attr('href')) {
      //           // location.href = $a.attr('href');
      //           navigate($a.attr('href'));

      //           console.log('navigate')
      //       }
      //   }
      // });



        // Make bootstrap a.dropdown-toggle that contains submenus clickable
        const $body = $(`body`);
        // $('.navbar').on('click', 'a.dropdown-toggle', function(event) {
        $(`.navbar .navbar-nav.menu-type-main`).on(`click`, `a.dropdown-toggle`, function (event) { // (event) => { // This cannot be here since it has different this!!!
          // do not visit link for mobile
          event.preventDefault();
 
          // visit link in non mobile
          // if ($body.hasClass('screen-lg') || $body.hasClass('screen-xl')) {
          if ($body.is(`.screen-lg, .screen-xl`)) {
            const $a = $(this);

            // Do not touch language switcher!!!
            if (`langDropdown` === $a.attr(`id`)) {
              return false;
            }

            // if ($a.parent().hasClass('show')) {
                if ($a.length && $a.attr(`href`)) {
                    // location.href = $a.attr('href');
                    
                    let href = $a.attr(`href`);
                        href = href.replace(`/` + intl.locale, ``);

                    navigate(href);
                    return null;
                }
            // }
          }
        });
  
  
  
        // @see: https://bootstrapious.com/p/bootstrap-multilevel-dropdown
        // ------------------------------------------------------- //
        // Multi Level dropdowns
        // ------------------------------------------------------ //
        $(`ul.dropdown-menu [data-toggle='dropdown']`).on(`click`, function(event) {
// s4n START
            // allow language switcher to be clicked!!!
            if ('langDropdown' === $(this).attr('id')) {
              return true;
            }
// s4n STOP
            event.preventDefault();
            event.stopPropagation();
    
            $(this).siblings().toggleClass("show");
    
            if (!$(this).next().hasClass('show')) {
              $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
            }
            $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(event) {
              $('.dropdown-submenu .show').removeClass("show");
            });
        });

        // const $isHeroSlider = $('.wp-block-gallery.hero')
        // if ($isHeroSlider.length) {
        //   setContext({
        //     isHeroSlider: true,
        //   })
        // }
    }

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    useEffect(() => {

      // SHOP EXT START
      // @see: https://github.com/gatsbyjs/gatsby/issues/14480
      // const store = configureStore();
      // store.dispatch(setCartToken(localStorage.getItem('cartToken') || Math.random().toString(36).substr(2)))
      // store.dispatch(cartFetch());
      // SHOP EXT STOP

      const { node } = props;
      let nodeId = null;
      let productId = null;

      if (node) {
        nodeId = node.drupal_internal__nid || null;
        productId = node.drupal_internal__product_id || null;
      }

      if (typeof window.g_soft4NetFrontConfig === `undefined`) {
        loadScripts(nodeId, productId);
      } else {
        callScripts(nodeId, productId);
      }
    }, []);

    // useLayoutEffect(() => {
    //   loadScripts();
    // })

    // @see: https://www.gatsbyjs.org/docs/use-static-query/
    const data = useStaticQuery(graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)

    // const useDrupalBlockContentBasic_Soft4NetFooterContactDetails = useDrupalBlockContentBasic(1);
    // console.log(useDrupalBlockContentBasic_Soft4NetFooterContactDetails);

    //console.log(useDrupalInterfaceTranslation('@items enabled', {'@items': 12}))
    //console.log(useDrupalInterfaceTranslation('soft4cms_all_rights_reserved'));

    // console.log(props.location)

    const { node } = props;
    const className = props.className || false;

    let nodeId = null;
    let productId = null;
    let oWrapperMainClassNode = null;

    if (node) {
      nodeId = node.drupal_internal__nid || null;
      productId = node.drupal_internal__product_id || null;

      oWrapperMainClassNode = {
        [node.internal.type]: true,
        [`drupal_internal__nid-${node.drupal_internal__nid}`]: node.drupal_internal__nid,
        [`drupal_internal__product_id-${node.drupal_internal__product_id}`]: node.drupal_internal__product_id,
        [kebabCase(node.title)]: true,
      }
    }

    const oClassNamesHtml = classNames({
      [`accessibility-high-contrast`]: props.isHighContrastMode,
      [`accessibility-font-size-${props.fontSize}`]: true,
    });

    const oClassNamesBody = classNames({
      [`${useScreenSize()}`]: true,
    });

    const oWrapperMainClass = classNames({
      'wrapper-main': true,
      [intl.locale]: true,
      hasHeroSlider: context.isHeroSlider,
      // [data.site.siteMetadata.title]: true,
      ...oWrapperMainClassNode,
      [className]: className,
    });

    const ServiceCookieCompliance = loadable(() => import('./s4n/Service/cookieCompliance'));
    // const ServiceTawkTo = loadable(() => import('./s4n/Service/tawkTo'));
    // const ServiceFacebookBadge = loadable(() => import('./s4n/Service/facebookBadge'))
    const ServiceScrollTop = loadable(() => import('./s4n/Service/scrollTop'));

    const hasFooterContactForm = [
      // 1,  // Home
      // 15, // Products
      // 16, // Kontakt
      // 17, // Portfolio
    ].includes(Number(nodeId));

    let ShopCheckoutIframe = null;
    if (`null` !== process.env.SOFT4NET_SHOP_PAGE_DRUPAL_ID) {
      ShopCheckoutIframe = loadable(() => import(`./../components/s4n/Shop/s4n/Checkout/Iframe`), {fallback: <Preloader />})
    }

    return (
    	<React.Fragment>
			{<Helmet
				htmlAttributes={{
					class: oClassNamesHtml
				}}
				bodyAttributes={{
          class: oClassNamesBody,
          'data-spy': `scroll`,
          'data-target': `.fixed-top`,
				}}
			/>}

			<div 
				// className={cssModule.root}
				className={oWrapperMainClass}
			>
				<Header siteTitle={data.site.siteMetadata.title} />

        {/* {nodeId !== Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID) && 
            <Breadcrumbs nid={nodeId} />
        } */}

				<main className="main">
					<S4nLayoutBlock name="main" nodeId={nodeId} productId={productId} hasFooterContactForm={hasFooterContactForm}>
						{children}
					</S4nLayoutBlock>
				</main>

				<Footer hasFooterContactForm={hasFooterContactForm} />

				{true && 
					<React.Fragment>
						<ServiceCookieCompliance />
						{/* <ServiceTawkTo serviceId="serviceId" /> */}
						<ServiceScrollTop />
						{/* <ServiceFacebookBadge serviceId="https://www.facebook.com/soft4net/" serviceName="soft4net.io" /> */}
					</React.Fragment>
				}
			</div>
    	</React.Fragment>
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

// @see: https://gatsbyguides.com/tutorial/authenticate-users-oauth
// @see: https://www.gatsbyjs.org/tutorial/authentication-tutorial/
// Initialize a new drupalOauth client which we can use to seed the context 
// provider.
// const drupalOauthClient = new drupalOauth({
//   drupal_root: process.env.SOFT4NET_SOURCE_BASE_URL,
//   client_id: process.env.SOFT4NET_DRUPAL_OAUTH_CLIENT_ID,
//   client_secret: process.env.SOFT4NET_DRUPAL_OAUTH_CLIENT_SECRET,
// })
// const LayoutWrapped = withDrupalOauthProvider(drupalOauthClient, Layout)
const LayoutWrapped = Layout

// const mapStateToProps = state => ({
//   fontSize: state.fontSize.fontSize,
//   isHighContrastMode: state.highContrast.isHighContrastMode,
// })

// export default connect(mapStateToProps)(LayoutWrapped);
export default LayoutWrapped;